export default {
  contact: {
    metatag: {
      title: 'Contacto - SIS Innov & Tech',
      description: 'Nuestros expertos están listos para satisfacer sus necesidades.',
    },
    title: 'Háblanos',
    form: {
      title: 'Somos SIS',
      text: 'La innovación y la transformación digital comienzan hoy. Lleva la evolución a tu empresa con SIS.',
      text2:
        'Prepárese para el futuro y manténgase a la vanguardia, optimizando procesos, reduciendo costos y expandiendo el crecimiento.',
      text3: 'Rellena el formulario y habla con nosotros!',
      labelName: 'Nombre*',
      labelLastName: 'Apellido*',
      labelCompany: 'Empresa*',
      labelPhone: 'Número de teléfono*',
      labelPosition: 'Puesto*',
      labelHowHelp: '¿Qué podemos ayudarte?*',
      labelReason: 'Razón para contactar*',
      labelHowFind: '¿Cómo se enteró de nosotros?*',
      labelMessage: 'Escribe tu mensaje',
      sendButton: 'QUIERO RECIBIR EL CONTACTO',
      disclaimer: 'No utilizamos su información para enviar ningún tipo de SPAM.',
      positionList: [
        { id: 1, text: 'CEO' },
        { id: 2, text: 'Director' },
        { id: 3, text: 'Gerente' },
        { id: 4, text: 'Coodinador' },
        { id: 5, text: 'Analista' },
        { id: 6, text: 'Asistente' },
        { id: 7, text: 'Pasante' },
        { id: 8, text: 'Otros' },
      ],
      howHelpList: [
        { id: 2, text: 'Células Ágiles y Squads' },
        { id: 3, text: 'Computación en la Nube' },
        { id: 4, text: 'Consultoría Estratégica' },
        { id: 5, text: 'Datos e Inteligencia' },
        { id: 6, text: 'Desarrollo' },
        { id: 7, text: 'Design Thinking' },
        { id: 8, text: 'DevOps' },
        { id: 9, text: 'Frameworks' },
        { id: 10, text: 'Otros' },
        { id: 11, text: 'Aseguramiento de la Calidad' },
        { id: 12, text: 'Soluciones de CRM' },
        { id: 13, text: 'Soluciones de ERP' },
        { id: 14, text: 'Soluciones de Bajo Código' },
        { id: 15, text: 'Soporte de Ambientes' },
        { id: 16, text: 'Búsqueda Tecnológica' },
        { id: 17, text: 'Transformación Digital' },
        { id: 18, text: 'UX y UI' },
        { id: 19, text: 'Proyecto a Medida' },
        { id: 20, text: 'SAP' },
        { id: 21, text: 'Ninguna Solución' },
      ],
      reasonList: [
        { id: 1, text: 'Quiero ser cliente' },
        { id: 2, text: 'Quiero ser proveedor' },
        { id: 3, text: 'Prensa' },
        { id: 4, text: 'Otros' },
      ],
      howFindList: [
        { id: 2, text: 'Google' },
        { id: 3, text: 'Events' },
        { id: 4, text: 'Facebook' },
        { id: 5, text: 'Recomendación' },
        { id: 6, text: 'Instagram' },
        { id: 7, text: 'Internet' },
        { id: 8, text: 'Periódico | revista impresa' },
        { id: 9, text: 'Linkedin' },
        { id: 10, text: 'Conferencia' },
        { id: 11, text: 'Youtube' },
        { id: 12, text: 'Otros' },
      ],
      sentMessage: 'Gracias por enviar el formulario.',
      sentMessageButton: 'Cerrar',
      sentMessageTitle: 'Éxito',
    },
    partOfTeam: {
      title: '¿Quieres ser parte de nuestro equipo?',
      text: 'Creemos que la satisfacción de nuestros empleados impacta directamente en la calidad de los servicios que brindamos a nuestros clientes.',
      buttonText: 'Ven a ser SIS',
    },
    maps: {
      title: 'Donde estamos',
      barueri: {
        text: 'Barueri',
        text2: 'Condomínio West Tower',
        text3: 'Alameda Rio Negro, 500 - 15º andar - Torre A',
        text4: 'Alphaville - Barueri, São Paulo',
        text5: 'CEP 06454-000',
        text6: 'Fone: +55 11 3711 6530',
      },
      rio: {
        text: 'Rio de Janeiro',
        text2: 'Centro Empresarial Cidade Nova | Teleporto',
        text3: 'Av. Pres. Vargas, 3131 - sala 403',
        text4: 'Centro, Rio de Janeiro - RJ',
        text5: 'CEP 20210-030',
        text6: 'Fone: +55 21 3993-2701',
      },
    },
    link: {
      text: 'Clientes y Aliados',
      text2: 'Casos de éxito',
    },
  },
}
