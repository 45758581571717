export default {
  contact: {
    metatag: {
      title: 'Contact Info - SIS Innov & Tech',
      description: 'Our experts are ready to meet your needs.',
    },
    title: 'Talk to us',
    form: {
      title: 'We are SIS',
      text: 'Innovation and Digital Transformation start today. Bring evolution to your company with SIS.',
      text2: 'Prepare for the future and for growth by optimizing without growth, costs and expanding growth.',
      text3: 'Fill out the form and talk to us!',
      labelName: 'Name*',
      labelLastName: 'Last Name*',
      labelCompany: 'Company*',
      labelPhone: 'Phone Number*',
      labelPosition: 'Position*',
      labelHowHelp: 'How can we help you?*',
      labelReason: 'Reason for contact*',
      labelHowFind: 'How did you find us?*',
      labelMessage: 'Message',
      sendButton: 'RECEIVE THE CONTACT',
      disclaimer: 'We do not use your information to send any type of SPAM.',
      positionList: [
        { id: 1, text: 'CEO' },
        { id: 2, text: 'Director' },
        { id: 3, text: 'Manager' },
        { id: 4, text: 'Coordinator' },
        { id: 5, text: 'Analyst' },
        { id: 6, text: 'Assistant' },
        { id: 7, text: 'Intern' },
        { id: 8, text: 'Others' },
      ],
      howHelpList: [
        { id: 2, text: 'Agile Cells and Squads' },
        { id: 3, text: 'Cloud Computing' },
        { id: 4, text: 'Strategic Consulting' },
        { id: 5, text: 'Data and Intelligence' },
        { id: 6, text: 'Development' },
        { id: 7, text: 'Design Thinking' },
        { id: 8, text: 'DevOps' },
        { id: 9, text: 'Frameworks' },
        { id: 10, text: 'Others' },
        { id: 11, text: 'Quality Assurance' },
        { id: 12, text: 'CRM Solutions' },
        { id: 13, text: 'ERP Solutions' },
        { id: 14, text: 'Low Code Solutions' },
        { id: 15, text: 'Environment Support' },
        { id: 16, text: 'Tech. Hunting' },
        { id: 17, text: 'Digital Transformation' },
        { id: 18, text: 'UX and UI' },
        { id: 19, text: 'Custom Project' },
        { id: 20, text: 'SAP' },
        { id: 21, text: 'No Solution' },
      ],
      reasonList: [
        { id: 1, text: 'I want to be a customer' },
        { id: 2, text: 'I want to be a supplier' },
        { id: 3, text: 'Press' },
        { id: 4, text: 'Others' },
      ],
      howFindList: [
        { id: 2, text: 'Google' },
        { id: 3, text: 'Events' },
        { id: 4, text: 'Facebook' },
        { id: 5, text: 'Recommendation' },
        { id: 6, text: 'Instagram' },
        { id: 7, text: 'Internet' },
        { id: 8, text: 'Newspapper | Printed Magazine' },
        { id: 9, text: 'Linkedin' },
        { id: 10, text: 'Lecture' },
        { id: 11, text: 'Youtube' },
        { id: 12, text: 'Others' },
      ],
      sentMessage: 'Thank you for submitting the form.',
      sentMessageButton: 'Close',
      sentMessageTitle: 'Success',
    },
    partOfTeam: {
      title: 'Want to be part of our team?',
      text: 'We believe that the satisfaction of our employees directly impacts the quality of the services we deliver to our customers.',
      buttonText: 'Come be SIS',
    },
    maps: {
      title: 'Where we are',
      barueri: {
        text: 'Barueri',
        text2: 'Condomínio West Tower',
        text3: 'Alameda Rio Negro, 500 - 15º andar - Torre A',
        text4: 'Alphaville - Barueri, São Paulo',
        text5: 'CEP 06454-000',
        text6: 'Fone: +55 11 3711 6530',
      },
      rio: {
        text: 'Rio de Janeiro',
        text2: 'Centro Empresarial Cidade Nova | Teleporto',
        text3: 'Av. Pres. Vargas, 3131 - sala 403',
        text4: 'Centro, Rio de Janeiro - RJ',
        text5: 'CEP 20210-030',
        text6: 'Fone: +55 21 3993-2701',
      },
    },
    link: {
      text: 'Clients and Partners',
      text2: 'Success Cases',
    },
  },
}
