export default {
  contact: {
    metatag: {
      title: 'Contato - SIS Innov & Tech',
      description: 'Nossos especialistas estão prontos para atender às suas necessidades.',
    },
    title: 'Fale com a Gente',
    form: {
      title: 'Somos a SIS',
      text: 'A Inovação e a Transformação Digital começam hoje. Traga a evolução para a sua empresa com a SIS.',
      text2:
        'Prepare-se para o futuro e esteja sempre a frente, otimizando processos, reduzindo custos e expandindo o crescimento.',
      text3: 'Preencha o formulário e fale com a gente!',
      labelName: 'Nome*',
      labelLastName: 'Sobrenome*',
      labelCompany: 'Empresa*',
      labelPhone: 'Celular*',
      labelPosition: 'Cargo*',
      labelHowHelp: 'Com o que podemos te ajudar?*',
      labelReason: 'Motivo do Contato*',
      labelHowFind: 'Descoberta da Marca',
      labelMessage: 'Escreva sua mensagem',
      sendButton: 'QUERO RECEBER CONTATO',
      disclaimer: 'Prometemos não utilizar suas informações de contato para enviar qualquer tipo de SPAM.',
      positionList: [
        { id: 1, text: 'CEO' },
        { id: 2, text: 'Diretor' },
        { id: 3, text: 'Gerente' },
        { id: 4, text: 'Coordenador' },
        { id: 5, text: 'Analista' },
        { id: 6, text: 'Assistente' },
        { id: 7, text: 'Estagiário' },
        { id: 8, text: 'Outros' },
      ],
      howHelpList: [
        { id: 2, text: 'Células Ágeis e Squads' },
        { id: 3, text: 'Cloud Computing' },
        { id: 4, text: 'Consultoria Estratégica' },
        { id: 5, text: 'Dados e Inteligência' },
        { id: 6, text: 'Desenvolvimento' },
        { id: 7, text: 'Design Thinking' },
        { id: 8, text: 'DevOps' },
        { id: 9, text: 'Frameworks' },
        { id: 10, text: 'Outros' },
        { id: 11, text: 'Quality Assurance' },
        { id: 12, text: 'Soluções de CRM' },
        { id: 13, text: 'Soluções de ERP' },
        { id: 14, text: 'Soluções de Low Code' },
        { id: 15, text: 'Sustentação de Ambientes' },
        { id: 16, text: 'Tech. Hunting' },
        { id: 17, text: 'Transformação Digital' },
        { id: 18, text: 'UX e UI' },
        { id: 19, text: 'Projeto Sob Medida' },
        { id: 20, text: 'SAP' },
        { id: 21, text: 'Nenhuma Solução' },
      ],
      reasonList: [
        { id: 1, text: 'Quero ser cliente' },
        { id: 2, text: 'Quero ser fornecedor' },
        { id: 3, text: 'Imprensa' },
        { id: 4, text: 'Outros' },
      ],
      howFindList: [
        { id: 2, text: 'Busca no Google' },
        { id: 3, text: 'Eventos' },
        { id: 4, text: 'Facebook' },
        { id: 5, text: 'Indicação' },
        { id: 6, text: 'Instagram' },
        { id: 7, text: 'Internet' },
        { id: 8, text: 'Jornal | Revista Impresso' },
        { id: 9, text: 'LinkedIn' },
        { id: 10, text: 'Palestra' },
        { id: 11, text: 'Youtube' },
        { id: 12, text: 'Outros' },
      ],
      sentMessage: 'Obrigado por enviar o formulário.',
      sentMessageButton: 'Fechar',
      sentMessageTitle: 'Sucesso',
    },
    partOfTeam: {
      title: 'Quer Fazer Parte da Nossa Equipe?',
      text: 'Acreditamos que a satisfação dos nossos colaboradores impacta diretamente na qualidade dos serviços que entregamos aos nossos clientes.',
      buttonText: 'Vem Ser SIS',
    },
    maps: {
      title: 'Onde Estamos',
      barueri: {
        text: 'Barueri',
        text2: 'Condomínio West Tower',
        text3: 'Alameda Rio Negro, 500 - 15º andar - Torre A',
        text4: 'Alphaville - Barueri, São Paulo',
        text5: 'CEP 06454-000',
        text6: 'Fone: +55 11 3711 6530',
      },
      rio: {
        text: 'Rio de Janeiro',
        text2: 'Centro Empresarial Cidade Nova | Teleporto',
        text3: 'Av. Pres. Vargas, 3131 - sala 403',
        text4: 'Centro, Rio de Janeiro - RJ',
        text5: 'CEP 20210-030',
        text6: 'Fone: +55 21 3993-2701',
      },
    },
    link: {
      text: 'Clientes e Parceiros',
      text2: 'Casos de Sucesso',
    },
  },
}
